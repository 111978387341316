/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
// import Vue from 'vue';

import main_service from '../../services/main-service';

// initial state
const state = {
    arr_location: [],
};

// getters
const getters = {
    popular: (state) => state.arr_location.filter((location) => location.is_popular),
    split_destinations: (state) => {
        // locations filtered into destinations and split by popular or not
        const popular_destinations = state.arr_location.filter(
            (location) => location.is_popular && location.is_destination,
        ).map((destination) => destination.name).sort();
        const other_destinations = state.arr_location.filter(
            (location) => !location.is_popular && location.is_destination,
        ).map((destination) => destination.name).sort();

        return [
            {
                type: 'Popular',
                items: popular_destinations,
            },
            {
                type: 'Other',
                items: other_destinations,
            },
        ];
    },
    departures: (state) => state.arr_location.filter(
        (location) => location.is_departure,
    ).map((location) => location.name).sort(),
    countries: (state) => state.arr_location.map((location) => location.country)
        .filter((val, idx, self) => self.indexOf(val) === idx)
        .sort(),
};

// actions
const actions = {
    // use a service to get content to match the names supplied
    fetchLocations({ commit }) {
        // clear current location
        commit('setLocations', []);

        // load new ones
        main_service.fetchLocations()
            .then((arr_location) => {
                commit('setLocations', arr_location);
            })
            .catch((err) => {
                // TODO: log externally
                console.error('failed to load content');
                console.error(err);
            });
    },
};

// mutations
const mutations = {
    setLocations(state, arr_location) {
        state.arr_location = arr_location;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
