/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import Vue from 'vue';

import main_service from '../../services/main-service';

// initial state
const state = {
    obj_content: {},
    home_images: [],
};

// getters
const getters = {
    getContent: (state) => (name) => state.obj_content[name],
    getAboutBios: (state) => [
        state.obj_content.about_bio_penny,
        state.obj_content.about_bio_natalie,
    ],
};

// actions
const actions = {
    // use a service to get content to match the names supplied
    fetchContent({ commit }, names) {
        main_service.fetchContent(names)
            .then((arr_content) => {
                arr_content.forEach((content) => {
                    commit('setContent', { name: content.name, content });
                });
            })
            .catch((err) => {
                // TODO: log externally
                console.error('failed to load content');
                console.error(err);
            });
    },
    // get content using a wildcard e.g. about_bio_%
    fetchContentWildcard({ commit }, term) {
        main_service.fetchContentWildcard(term)
            .then((arr_content) => {
                arr_content.forEach((content) => {
                    commit('setContent', { name: content.name, content });
                });
            })
            .catch((err) => {
                // TODO: log externally
                console.error('failed to load content');
                console.error(err);
            });
    },
    // get homeimages from api
    fetchHomeImages({ commit }) {
        main_service.fetchHomeImages()
            .then((home_images) => {
                commit('setHomeImages', home_images);
            })
            .catch((err) => {
                // TODO: log externally
                console.error('failed to load home images');
                console.error(err);
            });
    },
};

// mutations
const mutations = {
    setContent(state, { name, content }) {
        Vue.set(state.obj_content, name, content);
    },
    setHomeImages(state, home_images) {
        state.home_images = home_images;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
