/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import Vue from 'vue';

import main_service from '../../services/main-service';

// initial state
const state = {
    is_logged_in: false,
    show_login: false,
    user: {},
    // https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
    password_pattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
    password_pattern_message: 'Password be 8 characters long, contain upper and lowercase, atleast one number and a special character',
};

// getters
const getters = {
    is_verified: (state) => (typeof state.user.is_verified === 'undefined' ? false : state.user.is_verified),
};

// mutations
const mutations = {
    setLoggedIn(state, is_logged_in) {
        state.is_logged_in = is_logged_in;
    },
    setUser(state, user_data) {
        state.user = user_data;
    },
    showLogin(state) {
        state.show_login = true;
    },
    hideLogin(state) {
        state.show_login = false;
    },
};

// actions
const actions = {
    // set logged in state then update app status
    setLoggedIn({ commit }, is_logged_in) {
        commit('setLoggedIn', is_logged_in);
        commit('status/setSuccess', 'Successfully logged in', { root: true });
    },
    // check with the api if we have a valid session
    async userCheckLoggedIn({ commit }) {
        try {
            const status_array = await main_service.userCheckLoggedIn();
            commit('setLoggedIn', status_array[0]);
        } catch (err) {
            console.error('failed to verify session');
            console.error(err);
        }
    },
    async login({ commit }, data) {
        try {
            await main_service.login(data);
            commit('setLoggedIn', true);
            // update app status
            commit('status/setSuccess', 'Logged in', { root: true });
            Vue.prototype.$mixpanel.track('Log in');
        } catch (err) {
            console.log(err);
            commit('status/setWarning', err.response.data, { root: true });
            console.error(err);
        }
    },
    async register({ commit }, data) {
        try {
            await main_service.register(data);
            // this.$mixpanel.track('user registered');
            commit('setLoggedIn', true);
            // update app status
            commit('status/setSuccess', 'Registration complete', { root: true });
            Vue.prototype.$mixpanel.track('Sign Up');
            return true;
        } catch (err) {
            commit('status/setWarning', err.response.data, { root: true });
            return false;
        }
    },
    async update({ commit, dispatch }, data) {
        try {
            await main_service.userUpdate(data);
            // update app status
            commit('status/setSuccess', 'Update complete', { root: true });
            // reload user
            dispatch('getUserData');
            return true;
        } catch (err) {
            commit('status/setWarning', err.response.data, { root: true });
            return false;
        }
    },
    logout({ commit }) {
        main_service.logout()
            .then(() => {
                commit('setLoggedIn', false);
                Vue.prototype.$mixpanel.track('Logout');
            })
            .catch((errors) => {
                console.log(errors);
            });
    },
    async getUserData({ commit }) {
        try {
            const user = await main_service.getUserData();
            commit('setUser', user);
        } catch (err) {
            console.log(err);
            return false;
        }
        return true;
    },
    async reverify({ commit }) {
        const reverified = await main_service.reverify();
        if (reverified.success) {
            commit('status/setSuccess', 'Check email inbox for account verification link', { root: true });
        } else {
            commit('status/setDanger', reverified.msg, { root: true });
        }
    },
    verifyUser({ commit, dispatch }, verification) {
        main_service.verifyUser(verification)
            .then((response) => {
                commit('setVerified', 0);
                if (response.success) {
                    commit('status/setSuccess', 'Account verified', { root: true });
                    dispatch('getUserData');
                } else {
                    commit('status/setDanger', 'Could not verify account, click below to send a new link', { root: true });
                }
            })
            .catch((errors) => {
                commit('status/setDanger', 'Failed to verify account', { root: true });
                console.log(errors);
            });
    },
    requestDelete({ commit }) {
        main_service.requestDelete();
        commit('status/setSuccess', 'Check email inbox for delete account link', { root: true });
        Vue.prototype.$mixpanel.track('Request Delete');
    },
    deleteUser({ commit, dispatch }, delete_hash) {
        main_service.deleteUser(delete_hash)
            .then((response) => {
                // if it worked, we need to logout
                if (response.success) {
                    commit('status/setDanger', 'Account deleted', { root: true });
                    dispatch('logout');
                } else {
                    commit('status/setDanger', 'Could not delete account, click below to send a new link', { root: true });
                }
                Vue.prototype.$mixpanel.track('Delete User');
            })
            .catch((errors) => {
                commit('status/setDanger', 'Failed to delete account', { root: true });
                console.log(errors);
            });
    },
    sendResetLink({ commit }, email) {
        main_service.sendResetLink(email);
        commit('status/setSuccess', 'Check email inbox for reset password link', { root: true });
        Vue.prototype.$mixpanel.track('Send Password Reset');
    },
    resetPassword({ commit }, data) {
        main_service.resetPassword(data)
            .then((response) => {
                // if it worked, show the login screen
                commit('showLogin');
                if (response.success) {
                    commit('status/setSuccess', 'Password reset', { root: true });
                    Vue.prototype.$mixpanel.track('Password Reset Complete');
                } else {
                    commit('status/setDanger', 'Could not reset password, please try again', { root: true });
                }
            })
            .catch((errors) => {
                commit('status/setDanger', 'Failed to reset password', { root: true });
                console.log(errors);
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
