import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    },
    {
        path: '/account/resetpassword',
        name: 'Reset Password',
        component: () => import(/* webpackChunkName: "account" */ '../views/ResetPassword.vue'),
    },
    {
        path: '/routes',
        name: 'Routes',
        component: () => import(/* webpackChunkName: "routes" */ '../views/Routes.vue'),
    },
    {
        path: '/routes/:id/:name',
        name: 'Route',
        component: () => import(/* webpackChunkName: "route" */ '../views/Route.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
});

export default router;
