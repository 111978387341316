<template>
    <section class="home-search">
        <b-carousel
            v-model="carousel.carousel"
            animated="slide"
            :arrow="false"
            :has-drag="false"
            :autoplay="true"
            :pause-hover="true"
            :pause-info="false"
            :interval="5000"
            :repeat="true"
            :indicator="false"
        >
            <b-carousel-item v-for="(carousel, i) in carousel.carousels" :key="i">
                <section
                    :style="makebg(carousel)"
                >
                    <div class="home-carousel-item has-text-centered">
                        <span>&nbsp;</span>
                    </div>
                </section>
            </b-carousel-item>
        </b-carousel>
        <div class="home-search-wrapper container">
            <div class="home-search-title p-4">
                <h1>
                    <Title />
                </h1>
                <h2 class="subtitle">No flights. No hassle. No guilt.</h2>
            </div>
            <form
                id="home-search-form"
                class="home-search-form p-4"
                @submit.prevent="validateSelection"
            >
                <div v-if="!loading_search_params">
                    <strong>Choose up to {{tags_limit}} holiday types:</strong>
                    <div
                        class="holiday-type-wrapper mt-4"
                    >
                        <div
                            v-for="type in holiday_types"
                            :class="holidayTypeClasses(type)"
                            :key="type.name+'type'"
                            @click="localSelectHolidayType(type);"
                        >
                            <span
                                class="gr-icon gr-icon-small"
                                :class="`gr-icon-${type.icon}`"
                            ></span>
                            <div class="holiday-type-label" v-html="type.title"></div>
                        </div>
                    </div>
                    <div class="holiday-filter-wrapper columns mt-4">
                        <!-- <div class="column">
                            <b-field label="How many days?">
                                <b-select
                                    v-model="duration"
                                    expanded
                                >
                                    <option
                                        v-for="duration in all_durations"
                                        :value="`${duration.from},${duration.to}`"
                                        :key="`${duration.from}-${duration.to}-days`">
                                        {{ duration.title }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div> -->
                        <div class="column">
                            <b-field label="or choose destination">
                                <b-autocomplete
                                    v-model="selected_destination"
                                    group-field="type"
                                    group-options="items"
                                    :open-on-focus="true"
                                    :data="filtered_split_destinations"
                                    :clearable="true"
                                    :disabled="selected_types.length > 0"
                                    @select="option => (selected = option)"
                                >
                                </b-autocomplete>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field>
                                <b-button
                                    :disabled="loading_search_params"
                                    class="home-search-submit is-primary is-fullwidth"
                                    @click="validateSelection"
                                >
                                    Search
                                </b-button>
                            </b-field>
                        </div>
                    </div>
                    <div>
                        <a
                            class="is-pulled-right has-text-black show-me-everything"
                            @click="goToRoutes()">
                            Show me everything
                        </a>
                    </div>
                </div>
                <b-loading
                    :is-full-page="false"
                    v-model="loading_search_params"
                    :can-cancel="false"
                ></b-loading>
            </form>
        </div>
    </section>
</template>

<script>
// import router from 'router';
import {
    mapState,
    mapGetters,
    mapMutations,
    // mapActions,
} from 'vuex';

import Title from '@/components/Title.vue';

export default {
    components: {
        Title,
    },
    data() {
        return {
            selected_destination: '',
            duration: 'Any,Any',
            tags_limit: 2,
        };
    },
    mounted() {
        // always de-select every tag when loading home search
        this.selectHolidayType({ name: 'nothing' });
    },
    computed: {
        ...mapState({
            holiday_types: (state) => state.tag.holiday_types,
            locations: (state) => state.location.arr_location,
            durations: (state) => state.route.durations,
            home_images: (state) => state.content.home_images,
        }),
        ...mapGetters('location', [
            'split_destinations',
        ]),
        filtered_split_destinations() {
            const filtered = [];
            this.split_destinations.forEach((group) => {
                const items = group.items.filter((item) => {
                    const lowercase_item = item.toLowerCase();
                    const lowercase_search = this.selected_destination.toLowerCase();
                    return lowercase_item.indexOf(lowercase_search) >= 0;
                });
                if (items.length) {
                    filtered.push({ type: group.type, items });
                }
            });
            return filtered;
        },
        carousel() {
            return {
                dimensions: [
                    { w: 1280, h: 720 },
                    { w: 1920, h: 1080 },
                    { w: 2560, h: 1440 },
                    { w: 3840, h: 2160 },
                ],
                carousels: this.home_images,
            };
        },
        // loading until we have data for all filters
        loading_search_params() {
            return this.holiday_types.length === 0
                    || this.durations.length === 0
                    || this.locations.length === 0;
        },
        selected_types() {
            return this.holiday_types.filter((type) => type.selected);
        },
        selected_type_names() {
            return this.selected_types.map((type) => type.name);
        },
        all_durations() {
            return [{
                title: 'Any',
                from: 'Any',
                to: 'Any',
            }].concat(this.durations);
        },
    },
    methods: {
        // handle holiday type selection
        localSelectHolidayType(type) {
            // limit the number of tags the user can choose
            // don't let them choose a tag if enough are set
            // or if a destination is chosen
            if (
                (
                    !type.selected
                    && this.selected_types.length >= this.tags_limit
                ) || this.selected_destination !== ''
            ) {
                return false;
            }
            this.$mixpanel.track(
                'Home Select Holiday Type',
                {
                    type,
                },
            );
            return this.selectHolidayType(type);
        },
        // holiday type classes
        holidayTypeClasses(type) {
            const arr_classes = [
                'holiday-type',
                'has-text-centered',
            ];
            if (type.selected) {
                arr_classes.push('has-background-primary');
            }
            if (this.selected_destination !== '') {
                arr_classes.push('disabled');
            }
            return arr_classes;
        },
        makebg(image_url) {
            let route_image = image_url;
            const cloudinary_url_pattern = '^https://res.cloudinary.com/.*/image/upload/';
            const cloudinary_url_re = new RegExp(cloudinary_url_pattern, 'gi');
            const cloudinary_transforms_pattern = 'w_auto:100:1920,dpr_auto,q_auto,f_auto';
            const cloudinary_transforms_re = new RegExp(cloudinary_transforms_pattern, 'gi');

            /**
             * if it is a cloudinary image, make sure it has the correct overrides
             */
            if (
                route_image.match(cloudinary_url_re)
                && !route_image.match(cloudinary_transforms_re)
            ) {
                route_image = route_image.replace(/(upload\/)(v[0-9]{10})/, `$1${cloudinary_transforms_pattern}/$2`);
            }
            return `background: no-repeat center/cover url('${image_url}')`;
        },
        showWarning(msg) {
            this.$buefy.toast.open({
                message: msg,
                type: 'is-warning',
            });
        },
        validateSelection() {
            // track click
            this.$mixpanel.track(
                'Home Search',
                {
                    types: this.selected_type_names.join(','),
                    location: this.selected_destination,
                },
            );

            let load_routes = true;
            if (this.selected_types.length === 0 && this.selected_destination === '') {
                this.showWarning('Choose at least 1 holiday type');
                load_routes = false;
            }
            if (load_routes) {
                this.goToRoutes();
            }
        },
        goToRoutes() {
            this.$router.push(
                {
                    path: 'routes',
                    query: {
                        types: this.selected_type_names.join(','),
                        // duration: this.duration,
                        location: this.selected_destination,
                    },
                },
            );
        },
        ...mapMutations('tag', [
            'selectHolidayType',
        ]),
    },
};
</script>

<style scoped lang="scss">
    .home-carousel-item {
        /* force height of carousel to wrap search form */
        height: 900px;
    }

    .home-search {
        position: relative;
        // @include clip-down-left;
    }
    .home-search-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        padding-top: $navbar-desktop-height;
        padding-bottom: 50px;

        .home-search-title {
            flex: 1;
            max-width: 900px;
            .title,
            .subtitle {
                color: $white;
                text-shadow: 0px 0px 5px rgba(150, 150, 150, 1);
            }
        }

        .home-search-form {
            position: relative;
            background-color: $white;
            flex: 1;
            max-width: 900px;

            .label {
                font-size: 14px;
                color: white;
            }

            .show-me-everything {
                font-size: 14px;
                text-decoration: underline;
            }

            .holiday-type-wrapper {
                width: 100%;
                display: flex;
                gap: 0.5em;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                cursor: pointer;

                .holiday-type {
                    flex: 1 1 0px;
                    padding: 0.5em;
                    border-radius: 15px;
                    width: 100px;

                    &.disabled {
                        opacity: 0.25;
                    }

                    .holiday-type-label {
                        font-size: 14px;
                        text-align: center;
                    }
                }
            }

            .holiday-filter-wrapper {
                .column {
                    display: flex;
                    .field {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                    }
                }
            }

            .home-search-submit {
                color: $white;
                font-weight: 500;
            }
        }
    }

    /* override search types for narrow mobile */
    @media screen and (max-width: 400px) {
        .holiday-type-wrapper
            .holiday-type {
                &.is-one-third-mobile {
                    width: 50%;
                }
            }
    }

    @media (max-width: 1023px) {
        .home-search-wrapper {
            flex-direction: column;

             .home-search-title {
                .title,
                .subtitle {
                    text-align: center;
                }
            }

            .home-search-form {
                width: 90%;
                min-width: 304px;
                max-width: 375px;

                .show-me-everything {
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }

    @media (min-width: $tablet) {
        .home-search-wrapper {
            .home-search-form {
                max-width: 600px;
            }
        }

        .home-carousel-item {
            /* force height of carousel to wrap search form */
            height: 700px;
        }
    }

    /* desktop only stuff */
    @media (min-width: $desktop) {
        .home-search-wrapper {
            .home-search-form {
                .holiday-type-wrapper {
                    flex-wrap: nowrap;
                }
            }
        }
        .home-carousel-item {
            /* force height of carousel to wrap search form */
            height:500px;
        }
    }

</style>
