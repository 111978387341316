import Vue from 'vue';
import Buefy from 'buefy';
import VueSmoothScroll from 'vue2-smooth-scroll';
import VueMixpanel from 'vue-mixpanel';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import App from './App.vue';
import router from './router';
import store from './store';

// import '@fortawesome/fontawesome-free/css/fontawesome.min.css';

Sentry.init({
    Vue,
    dsn: 'https://dc93732e8de948f08ba7b74fcc197de1@o741839.ingest.sentry.io/5786758',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

Vue.use(VueSmoothScroll);

Vue.use(Buefy);

Vue.use(VueMixpanel, {
    token: '561e08307b78b35f41c30a43f6d360a2',
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
