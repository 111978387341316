<template>
    <section class="section home-involved pt-5">
        <div class="container">
            <h2 class="title has-text-centered script-font">Let's be friends</h2>
            <div class="columns mt-4">
                <div class="columns plus-points has-text-centered">
                    <div class="column">
                        <span class="gr-icon gr-icon-small gr-icon-signup"></span>
                        <p class="p-4">
                            Hear about new routes, exclusive inspiration and what we’ve been
                            up to at Good Routes HQ.
                        </p>
                        <a
                            class="button is-primary"
                            href="#"
                            @click="scrollToBottom"
                        >
                            Sign up to our newsletter
                        </a>
                    </div>
                    <div class="column">
                        <span class="gr-icon gr-icon-small gr-icon-route"></span>
                        <p class="p-4">
                            Know about a great route you haven’t seen on here? Get in touch!
                        </p>
                        <a
                            target="_blank"
                            class="button is-primary"
                            href="https://forms.gle/1yLVZ9xaNFjB8JnD8"
                        >
                            Share your Good Route
                        </a>
                    </div>
                    <div class="column">
                        <span class="gr-icon gr-icon-small gr-icon-note"></span>
                        <p class="p-4">
                            We’re in the early stages, and we’d love to hear your thoughts.
                            We can take it, promise.
                        </p>
                        <a
                            target="_blank"
                            class="button is-primary"
                            href="https://forms.gle/bFScWmpnHkuPW96s6"
                        >
                            Give us feedback
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    methods: {
        scrollToBottom() {
            const footer = document.getElementById('mc_embed_signup');
            this.$smoothScroll({
                scrollTo: footer,
                hash: '#signup',
            });
        },
    },
};
</script>

<style scoped lang="scss">
    .title {
        font-size: 62px;
        line-height: 79px;
    }

    .columns {
        .column {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .button {
                color: $white;
            }
        }
    }

    @media screen and (max-width: $tablet) {
        .title {
            font-size: 55px;
        }
    }
</style>
