<template>
  <div class="home">
    <HomeSearch />
    <HomeLinks
        class="home-links-discover"
        title="Discover"
        subtitle="Flight-free holidays to take right now"
        :links="this.getLinksForType('home_discover')"
    />
    <HomeWelcome
        v-if="homepage_welcome"
        :msg="homepage_welcome.title"
        :text="homepage_welcome.content"
        themeclass="success"
    />
    <HomeLinks
        class="home-links-planning"
        title="Do it like a pro"
        subtitle="Your insider guide to making the most of your trip"
        :links="this.getLinksForType('home_planning')"
    />
    <HomeInvolved />
    <HomeLinks
        class="home-links-save"
        title="Save money on your Good Route"
        subtitle="We've found the best deals, so you don't have to"
        :links="this.getLinksForType('home_savings')"
    />
  </div>
</template>

<script>
import axios from 'axios';

// @ is an alias to /src
import HomeWelcome from '@/components/HomeWelcome.vue';
import HomeSearch from '@/components/HomeSearch.vue';
import HomeLinks from '@/components/HomeLinks.vue';
import HomeInvolved from '@/components/HomeInvolved.vue';

import {
    // mapState,
    mapGetters,
    mapActions,
} from 'vuex';

export default {
    name: 'Home',
    components: {
        HomeWelcome,
        HomeSearch,
        HomeLinks,
        HomeInvolved,
    },
    mounted() {
        // get links
        axios
            .get('/api/data/links')
            .then((result) => {
                this.links = result.data;
            });
        // get content
        this.fetchContent('homepage_welcome');
    },
    data() {
        return {
            links: [],
        };
    },
    computed: {
        homepage_welcome() {
            return this.getContent('homepage_welcome');
        },
        ...mapGetters('content', [
            'getContent',
        ]),
    },
    methods: {
        getLinksForType(type) {
            return this.links.filter((link) => type === link.type);
        },
        ...mapActions('content', [
            'fetchContent',
        ]),
    },
};
</script>

<style scoped lang="scss">
    .home-links-discover {
        background-color: $pink;
        @include clip-down-left;
    }

    .home-links-planning {
        background-color: $blue;
        @include clip-down-right;
    }

    .home-links-save {
        border-top: 3px dashed $green;
        background-color: $pink-light;
        position: relative;
        &::before {
            position: absolute;
            top: -17px;
            left: 10%;
            content: " ";
            width: 42px;
            height: 33px;
            white-space: pre;
            background: no-repeat center -5% url('/image/scissors.png');
        }
    }
</style>
