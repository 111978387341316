<template>
  <div id="app">
    <NavBar
        :class="{
            'navbar-home' : is_home,
        }"
    />
    <router-view/>
    <Footer />
    <b-modal
        v-model="show_login"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-label="Login form"
        aria-modal
    >
        <template>
            <Login @close="hideLogin"></Login>
        </template>
    </b-modal>
  </div>
</template>

<script>
import {
    mapState,
    // mapGetters,
    mapMutations,
    mapActions,
} from 'vuex';

import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import Login from '@/components/Login.vue';

export default {
    name: 'App',
    components: {
        NavBar,
        Footer,
        Login,
    },
    mounted() {
        // get home page images
        this.fetchHomeImages();

        // check session on App load
        this.userCheckLoggedIn();

        // get filter data
        this.fetchTagsForHolidayType();
        this.fetchTagsForSearchRefinement();
        this.fetchLocations();
        this.fetchDurations();
        // this.fetchDurationLimits();
    },
    computed: {
        ...mapState({
            success: (state) => state.status.success,
            warning: (state) => state.status.warning,
            danger: (state) => state.status.danger,
        }),
        show_login: {
            get() {
                return this.$store.state.user.show_login;
            },
            set() {
                console.log('setting now');
                this.hideLogin();
            },
        },
        is_home() {
            return this.$route.path === '/';
        },
    },
    watch: {
        success(msg) {
            this.notify(msg, 'success');
        },
        warning(msg) {
            this.notify(msg, 'warning');
        },
        danger(msg) {
            this.notify(msg, 'danger');
        },
    },
    methods: {
        ...mapMutations('status', [
            'clear',
        ]),
        ...mapMutations('user', [
            'hideLogin',
        ]),
        ...mapActions('tag', [
            'fetchTagsForHolidayType',
            'fetchTagsForSearchRefinement',
        ]),
        ...mapActions('location', [
            'fetchLocations',
        ]),
        ...mapActions('content', [
            'fetchHomeImages',
        ]),
        ...mapActions('route', [
            'fetchDurations',
            // 'fetchDurationLimits',
        ]),
        ...mapActions('user', [
            'userCheckLoggedIn',
        ]),
        notify(msg, type) {
            if (msg !== '') {
                this.$buefy.toast.open({
                    message: msg,
                    type: `is-${type}`,
                });
                // once the message has show clear the status
                this.clear();
            }
        },
    },
};
</script>

<style lang="scss">
    // @import "./assets/scss/_main.scss";

    body {
        overflow-x: hidden;
    }

    .button {
        font-size: 17px;
        font-weight: $font-semi-bold;
    }

    .modal-background {
        background-color: $white;
        opacity: 0.65;
    }
</style>
