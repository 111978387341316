import axios from 'axios';
import axiosCancel from 'axios-cancel';
import * as Sentry from '@sentry/vue';

axiosCancel(axios, {
    debug: false,
});

function serviceLog() {
    // console.log(' > main-service: ', msg);
}

function callAPI(config) {
    const call_config = JSON.parse(JSON.stringify(config));
    if (typeof call_config.data === 'undefined') {
        call_config.data = {};
    }
    if (typeof call_config.params === 'undefined') {
        call_config.params = {};
    }

    if (typeof call_config.method === 'undefined') {
        call_config.method = 'GET';
    }

    // cancel any other calculations to stop stacking
    axios.cancel(call_config.request_id);

    // we return a promise, so the then/catch functions in our components
    // can wait for the responses
    const promise = new Promise((resolve, reject) => {
        axios({
            url: call_config.path,
            method: call_config.method,
            // params are the URL parameters to be sent with the request
            // use config for POST
            params: call_config.params,
            requestId: call_config.request_id,
            data: call_config.data,
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                Sentry.captureException(err);
                reject();
            });
    });

    return promise;
}

// define module exports
// public methods that can be used once the sevice is imported
export default {
    userCheckLoggedIn() {
        // define data to be passed to the api
        const config = {
            request_id: 'userCheckLoggedIn',
            path: '/api/user/checkLoggedIn',
        };

        serviceLog('checking session status');

        // call the API and return the result
        return callAPI(config);
    },

    login(data) {
        // define data to be passed to the api
        const config = {
            request_id: 'login',
            path: '/api/user/login',
            method: 'POST',
            data,
        };

        serviceLog('logging in');
        // call the API and return the result
        return callAPI(config);
    },

    register(data) {
        // define data to be passed to the api
        const config = {
            request_id: 'register',
            path: '/api/user/register',
            method: 'POST',
            data,
        };

        serviceLog('registering user');

        // call the API and return the result
        return callAPI(config);
    },

    userUpdate(data) {
        // define data to be passed to the api
        const config = {
            request_id: 'userUpdate',
            path: '/api/user/update',
            method: 'PUT',
            data,
        };

        serviceLog('updating user');

        // call the API and return the result
        return callAPI(config);
    },

    logout() {
        // define data to be passed to the api
        const config = {
            request_id: 'logout',
            path: '/api/user/logout',
        };

        serviceLog('logging out');

        // call the API and return the result
        return callAPI(config);
    },

    getUserData() {
        // define data to be passed to the api
        const config = {
            request_id: 'getUserData',
            path: '/api/user/data',
        };

        serviceLog('getting user data');

        // call the API and return the result
        return callAPI(config);
    },

    reverify() {
        // define data to be passed to the api
        const config = {
            request_id: 'reverify',
            path: '/api/user/reverify',
        };

        serviceLog('verifying user');

        // call the API and return the result
        return callAPI(config);
    },

    verifyUser(verification) {
        // define data to be passed to the api
        const config = {
            request_id: 'verifyUser',
            path: '/api/user/verify',
            method: 'POST',
            data: {
                verification,
            },
        };

        serviceLog('verifying user');

        // call the API and return the result
        return callAPI(config);
    },

    requestDelete() {
        // define data to be passed to the api
        const config = {
            request_id: 'requestDelete',
            path: '/api/user/requestDelete',
        };

        serviceLog('requesting to delete user');

        // call the API and return the result
        return callAPI(config);
    },

    deleteUser(delete_hash) {
        // define data to be passed to the api
        const config = {
            request_id: 'deleteUser',
            path: '/api/user/delete',
            method: 'DELETE',
            data: {
                delete_hash,
            },
        };
        serviceLog('verifying user');
        // call the API and return the result
        return callAPI(config);
    },

    sendResetLink(email) {
        // define data to be passed to the api
        const config = {
            request_id: 'sendResetLink',
            path: '/api/user/resetlink',
            params: {
                email,
            },
        };
        serviceLog('sending password reset link');
        // call the API and return the result
        return callAPI(config);
    },

    resetPassword(data) {
        // define data to be passed to the api
        const config = {
            request_id: 'resetPassword',
            path: '/api/user/resetpassword',
            method: 'PUT',
            data,
        };
        serviceLog('reset password');
        // call the API and return the result
        return callAPI(config);
    },

    fetchContent(names) {
        // define data to be passed to the api
        const config = {
            request_id: 'fetchContent',
            path: `/api/content/${names}`,
        };

        serviceLog('fetching content');

        // call the API and return the result
        return callAPI(config);
    },

    fetchContentWildcard(term) {
        // define data to be passed to the api
        const config = {
            request_id: 'fetchContentWildcard',
            path: `/api/content/wildcard/${term}`,
        };

        serviceLog('fetching content based on wildcard search');

        // call the API and return the result
        return callAPI(config);
    },

    fetchTagsForType(type) {
        // define data to be passed to the api
        const config = {
            request_id: `fetchTagsForType${type}`,
            path: `/api/tags/${type}`,
        };

        serviceLog(`fetching tags for ${type}`);

        // call the API and return the result
        return callAPI(config);
    },

    fetchRoutes({
        types,
        location,
        country,
        refinements,
        departure,
        durations,
        include_ferry,
    }) {
        // define params to be passed to the api
        const config = {
            request_id: 'fetchRoutes',
            path: '/api/data/routes',
            params: {
                types,
                location,
                country,
                refinements,
                departure,
                durations,
                include_ferry,
            },
        };

        serviceLog('fetching routes');

        // call the API and return the result
        return callAPI(config);
    },

    fetchSingleRoute({ id }) {
        // define data to be passed to the api
        const config = {
            request_id: 'fetchSingleRoute',
            path: '/api/data/route',
            params: {
                id,
            },
        };

        serviceLog('fetching route details');

        // call the API and return the result
        return callAPI(config);
    },

    fetchLocations() {
        // define data to be passed to the api
        const config = {
            request_id: 'fetchLocations',
            path: '/api/data/locations',
        };

        serviceLog('fetching locations');

        // call the API and return the result
        return callAPI(config);
    },

    fetchDurations() {
        // define data to be passed to the api
        const config = {
            request_id: 'fetchDurations',
            path: '/api/data/durations',
        };

        serviceLog('fetching durations');

        // call the API and return the result
        return callAPI(config);
    },

    fetchDurationLimits() {
        // define data to be passed to the api
        const config = {
            request_id: 'fetchDurationLimits',
            path: '/api/data/durationlimits',
        };

        serviceLog('fetching duration limits');

        // call the API and return the result
        return callAPI(config);
    },

    fetchHomeImages() {
        // define data to be passed to the api
        const config = {
            request_id: 'fetchHomeImages',
            path: '/api/images/home',
        };

        serviceLog('fetching home images');

        // call the API and return the result
        return callAPI(config);
    },

    toggleFavourite(data) {
        // define data to be passed to the api
        const config = {
            request_id: 'toggleFavourite',
            path: '/api/user/togglefavourite',
            method: 'PUT',
            data,
        };
        serviceLog('toggle favourite');
        // call the API and return the result
        return callAPI(config);
    },

    setRating(data) {
        // define data to be passed to the api
        const config = {
            request_id: 'setRating',
            path: '/api/user/setrating',
            method: 'PUT',
            data,
        };
        serviceLog('set rating');
        // call the API and return the result
        return callAPI(config);
    },

};
