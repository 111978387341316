<template>
    <b-navbar wrapper-class="container">
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img class="navbar-logo" alt="Good Routes logo" src="../assets/logo.png">
            </b-navbar-item>
        </template>
0
        <template #end>
            <b-navbar-item href="#">
                <router-link to="/">Home</router-link>
            </b-navbar-item>
            <b-navbar-item href="#">
                <router-link to="/routes">Good Routes</router-link>
            </b-navbar-item>
            <b-navbar-item href="#">
                <a href="https://natalie3489.wixsite.com/good-routes-blog/">Blog</a>
            </b-navbar-item>
            <b-navbar-item href="#">
                <router-link to="/about">About</router-link>
            </b-navbar-item>
            <b-navbar-item href="#">
                <router-link to="/account" class="account-link" v-if="is_logged_in">
                    Account
                    <b-icon icon="account-outline" size=""></b-icon>
                </router-link>
                <a href="#" @click="showLogin()" v-else>
                    Account
                </a>
            </b-navbar-item>
            <b-navbar-item
                href="https://www.instagram.com/goodroutesuk/"
                target="_blank"
                class="instagram-link"
            >
                <b-icon icon="instagram" size="is-medium"></b-icon>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
import {
    mapState,
    mapMutations,
} from 'vuex';

export default {
    name: 'NavBar',
    computed: {
        ...mapState({
            is_logged_in: (state) => state.user.is_logged_in,
        }),
    },
    methods: {
        ...mapMutations('user', [
            'showLogin',
        ]),
    },
};
</script>

<style lang="scss">
    nav.navbar {
        background-color: $primary;

        &.navbar-home {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: none;
        }

        .navbar-brand {
            align-items: center;

            .navbar-burger {
                color: $white;
                margin-right: 0.75rem;
                &:hover {
                    background: none;
                }

                span {
                    height: 3px;
                    width: 30px;

                    &:nth-child(1) {
                        top: calc(50% - 10px);
                    }
                    &:nth-child(3) {
                        top: calc(50% + 8px);
                    }
                }

                &.is-active {
                    span {
                        &:nth-child(1) {
                            top: calc(50% - 8px);
                        }
                        &:nth-child(3) {
                            top: calc(50% + 2px);
                        }
                    }
                }
            }
        }

        .navbar-logo {
            max-width: 100px;
            max-height: 100px;
        }

        .navbar-item {
            &:hover {
                color: $white;
                background-color: transparent;
                text-decoration: underline;
            }
            &:focus,
            &:focus-within {
                background-color: transparent;
            }
        }

        a {
            color: $white;
            font-size: 18px;
            font-weight: $font-semi-bold;
            padding: 1rem 0.75rem;

            // .account-link {
            //     .icon {
            //         margin-bottom: -5px;
            //     }
            // }
        }

        .navbar-menu {
            a {
                color: $primary;
            }

            .instagram-link {
                margin-left: 0.75rem;
            }
        }

        &.navbar-home {
            .navbar-brand {
                align-items: center;

                .navbar-burger {
                    color: $white;
                }
            }
        }
    }

    @media screen and (min-width: $desktop) {
        nav.navbar {
            .navbar-menu {
                a {
                    color: $white;
                }
                .instagram-link {
                    margin-left: 0;
                }
            }

            &.navbar-home {
                .navbar-brand {
                    .navbar-burger {
                        color: $white;
                    }
                }
            }
        }
    }
</style>
