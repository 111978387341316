/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

// initial state
const state = {
    success: '',
    warning: '',
    danger: '',
};

// mutations
const mutations = {
    setSuccess(state, msg) {
        state.success = msg;
    },
    setWarning(state, msg) {
        state.warning = msg;
    },
    setDanger(state, msg) {
        state.danger = msg;
    },
    setStatus(state, { msg, type }) {
        state[type] = msg;
    },
    clear() {
        state.success = '';
        state.warning = '';
        state.danger = '';
    },
};

export default {
    namespaced: true,
    state,
    // getters,
    // actions,
    mutations,
};
