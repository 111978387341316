/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
// import Vue from 'vue';

import main_service from '../../services/main-service';

// state
const state = {
    holiday_types: [],
    search_refinements: [],
};

// getters
const getters = {
    getSelectedTypes: (state) => state.holiday_types.filter((type) => type.selected),
};

// mutations
const mutations = {
    setHolidayTypes(state, arr_types) {
        // add the "selected" property to all types
        const full_holiday_types = arr_types.map((type) => {
            const new_type = type;
            new_type.selected = false;
            return new_type;
        });
        state.holiday_types = full_holiday_types;
    },
    selectHolidayType(state, selected_type) {
        // if the type is everything, select all the others
        if (selected_type.name === 'everything') {
            // update array and replace original via map
            state.holiday_types = state.holiday_types.map((type) => {
                const new_type = type;
                new_type.selected = true;
                return type;
            });
        } else if (selected_type.name === 'nothing') {
            // update array and replace original via map
            state.holiday_types = state.holiday_types.map((type) => {
                const new_type = type;
                new_type.selected = false;
                return type;
            });
        } else {
            /**
             * if not "everything" just toggle it
             * recreate the option and use .set to overcome reactivity issues
             */
            const idx_type = state.holiday_types.indexOf(selected_type);
            const new_type = selected_type;
            new_type.selected = !selected_type.selected;
            state.holiday_types[idx_type] = new_type;
        }
    },
    setSearchRefinements(state, arr_refinements) {
        state.search_refinements = arr_refinements;
    },
};

// actions
const actions = {
    // use a service to get content to match the names supplied
    async fetchTagsForHolidayType({ commit }) {
        try {
            const arr_types = await main_service.fetchTagsForType('holidaytype');
            commit('setHolidayTypes', arr_types);
        } catch (err) {
            console.error('failed to load type tags');
            console.error(err);
        }
    },
    async fetchTagsForSearchRefinement({ commit }) {
        try {
            const arr_types = await main_service.fetchTagsForType('searchrefinement');
            commit('setSearchRefinements', arr_types);
        } catch (err) {
            console.error('failed to load search tags');
            console.error(err);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
