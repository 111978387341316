<template>
    <section class="section home-links pt-5 pb-5">
        <div class="container">
            <h1
                class="title script-font"
                :class="{ 'has-text-centered' : titlecentered }"
            >{{ title }}</h1>
            <h2
                class="subtitle"
                :class="{ 'has-text-centered' : titlecentered }"
            >{{ subtitle }}</h2>
            <b-carousel-list
                v-model="test"
                :data="links"
                :arrow="true"
                :arrow-hover="false"
                :items-to-show="items_per_screen_size"
            >
                <template #item="link">
                    <a :href="link.href" target="_blank">
                        <div class="card">
                            <div class="card-image">
                                <figure
                                    class="image is-4by3"
                                    :style="background(link.image_url)"
                                >
                                </figure>
                            </div>
                            <div class="card-content">
                                <div class="content">
                                    <p class="title is-5">{{ link.title }}</p>
                                    <p class="subtitle is-6" v-if="link.subtitle">
                                        {{ link.subtitle }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </a>
                </template>
            </b-carousel-list>
        </div>
    </section>
</template>

<script>
export default {
    name: 'HomeLinks',
    props: {
        title: String,
        subtitle: String,
        titlecentered: {
            type: Boolean,
            default: false,
        },
        links: Array,
    },
    data() {
        return {
            test: 0,
            arrow: true,
            drag: true,
            gray: false,
            opacity: false,
            values: 1,
            perList: 4,
            increment: 1,
            repeat: false,
        };
    },
    computed: {
        items_per_screen_size() {
            if (window.matchMedia('(max-device-width: 425px)').matches) {
                return 1;
            }
            if (window.matchMedia('(max-device-width: 768px)').matches) {
                return 2;
            }
            return 3;
        },
    },
    methods: {
        // if the image fails to load, set the placeholder
        setPlaceHolder(e) {
            console.log('setting');
            e.target.src = '../assets/logo.png';
        },
        background(src) {
            // set default image
            const tile_image = 'background: no-repeat center/90% url(/image/logo.png/);';
            // only try to load image if it's not blank
            if (src !== '') {
                return `background: no-repeat center top/cover url(${src});`;
            }
            return tile_image;
        },
    },
};
</script>

<style lang="scss">
    .carousel-arrow {
        .icon,
        .icon:hover {
            border: 0 !important;
            color: $black !important;
            opacity: 0.5;
            margin-top: -50px !important;
            width: 2em;
            height: 2em;
        }

        .icon:hover {
            opacity: 0.8;
        }
    }
</style>

<style scoped lang="scss">
    .title,
    .subtitle {
        color: $black;
    }

    .title {
        font-size: 62px;
        line-height: 79px;
    }

    .subtitle {
        font-size: 18px;
    }

    .carousel-list,
    .carousel-list.has-shadow {
        box-shadow: none;
    }

    .card {
        background: none;
        box-shadow: none;
        padding: 0.25em;
        .card-content {
            padding: 1em;
            .content {
                .title {
                    text-align: center;
                    font-weight: $font-semi-bold;
                    font-size: 20px;
                    line-height: 27px;
                }
                .subtitle {
                    text-align: center;
                    font-size: 14px;
                    font-weight: $font-semi-bold;
                    line-height: 18px;
                }
            }
        }
    }

    .card-content {
        background: none;
    }

    .home-links-planning {
        .subtitle {
            color: $white;
        }

        .card-content {
            .title {
                color: $white;
            }
            .subtitle {
                color: $black;
            }
        }
    }

    .home-links-save {
        .title,
        .subtitle {
            text-align: center;
        }
        .subtitle {
            color: $black;
        }
        .card {
            .card-content {
                .content {
                    .title {
                        color: $black;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $tablet) {
        .title {
            font-size: 55px;
        }
    }
</style>
