/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import Vue from 'vue';

import main_service from '../../services/main-service';

// initial state
const state = {
    is_loading: false,
    arr_routes: [],
    route_detail: {},
    durations: [],
    duration_limits: [],
    show_full_itinerary: true,
};

// mutations
const mutations = {
    setLoading(state, is_loading) {
        state.is_loading = is_loading;
    },
    setRoutes(state, arr_routes) {
        state.arr_routes = arr_routes;
    },
    setRouteDetail(state, route_detail) {
        state.route_detail = route_detail;
    },
    setDurations(state, durations) {
        state.durations = durations;
    },
    setRouteFavourite(state, { is_favourite, route_id }) {
        const route_to_change = state.arr_routes.filter((route) => route.id === route_id);
        if (route_to_change.length) {
            // update route in routes array if we have it
            route_to_change[0].is_favourite = is_favourite ? 1 : 0;
        }
        // update route detail
        state.route_detail.detail.is_favourite = is_favourite ? 1 : 0;
    },
    setRouteRating(state, { rating, route_id }) {
        const route_to_change = state.arr_routes.filter((route) => route.id === route_id)[0];
        route_to_change.rating = rating;
        state.route_detail.detail.rating = rating;
    },
    setShowFullItinerary(state, val) {
        state.show_full_itinerary = val;
    },
};

// actions
const actions = {
    // load routes based on supplied params
    async fetchRoutes({ commit }, {
        types,
        departure,
        durations,
        location,
        refinements,
        country,
        include_ferry,
        single_location,
    }) {
        // set loading to true
        commit('setLoading', true);

        // clear current routes
        commit('setRoutes', []);

        try {
            // load new ones
            const arr_routes = await main_service.fetchRoutes({
                types,
                departure,
                durations,
                location,
                refinements,
                country,
                include_ferry,
                single_location,
            });

            commit('setRoutes', arr_routes);
        } catch (err) {
            // TODO: log externally
            console.error('failed to load content');
            console.error(err);
        }

        // if it worked or not, we've stopped loading
        commit('setLoading', false);
    },
    // use a service to get content to match the names supplied
    fetchSingleRoute({ commit }, id) {
        // set loading to true
        commit('setLoading', true);

        // clear current routes
        commit('setRouteDetail', {});

        // load new ones
        main_service.fetchSingleRoute({ id })
            .then((route_detail) => {
                commit('setRouteDetail', route_detail);
            })
            .catch((err) => {
                // TODO: log externally
                console.error('failed to load route');
                console.error(err);
            })
            .finally(() => {
                // if it worked or not, we've stopped loading
                commit('setLoading', false);
            });
    },
    // call service to get durations
    fetchDurations({ commit }) {
        // set loading to true
        commit('setLoading', true);

        // load new ones
        main_service.fetchDurations()
            .then((res) => {
                commit('setDurations', res);
            })
            .catch((err) => {
                // TODO: log externally
                console.error('failed to load durations');
                console.error(err);
            })
            .finally(() => {
                // if it worked or not, we've stopped loading
                commit('setLoading', false);
            });
    },
    // turn favourite on/off
    toggleFavourite({ commit }, { is_favourite, route_id }) {
        // load new ones
        main_service.toggleFavourite({ is_favourite, route_id })
            .then(() => {
                commit('setRouteFavourite', { is_favourite, route_id });
                Vue.prototype.$mixpanel.track('Toggle Favourite');
            })
            .catch((err) => {
                // TODO: log externally
                console.error('failed to set favourite');
                console.error(err);
            });
    },
    // set the rating
    setRating({ commit }, { new_rating, current_rating, route_id }) {
        // if the new/curent ratings are the same, we're un-rating (setting to 0)
        const rating = new_rating === current_rating ? 0 : new_rating;
        main_service.setRating({ rating, route_id })
            .then(() => {
                commit('setRouteRating', { rating, route_id });
                commit('status/setSuccess', 'User rating saved', { root: true });
            })
            .catch((err) => {
                // TODO: log externally
                console.error('failed to set rating');
                console.error(err);
            });
    },
};

export default {
    namespaced: true,
    state,
    // getters,
    actions,
    mutations,
};
