<template>
    <footer class="footer">
        <div class="container content">
            <div class="columns">
                <div class="column">
                    <h2 class="title">Sign up to our newsletter</h2>
                    <p>
                        Get signed up to our newsletter and hear about the latest and greatest
                        Good Routes
                    </p>
                    <!-- Begin Mailchimp Signup Form -->
                    <div id="mc_embed_signup" class="mc-signup-form has-text-centered">
                        <form
                            action="https://goodroutes.us7.list-manage.com/subscribe/post?u=4c20b5369e88e53fd9a63c7b3&amp;id=b647552993"
                            method="post"
                            id="mc-embedded-subscribe-form"
                            name="mc-embedded-subscribe-form"
                            class="validate"
                            target="_blank"
                            novalidate
                        >
                            <div id="mc_embed_signup_scroll">
                                <div class="field mc-signup-form-fields">
                                    <div class="control field mc-signup-form-email">
                                        <input
                                            type="email"
                                            value=""
                                            name="EMAIL"
                                            class="email input"
                                            id="mce-EMAIL"
                                            placeholder="Email address"
                                            required
                                        >
                                    </div>
                                    <!-- honeypot -->
                                    <div
                                        style="position: absolute; left: -5000px;"
                                        aria-hidden="true"
                                    >
                                        <input
                                            type="text"
                                            name="b_4c20b5369e88e53fd9a63c7b3_b647552993"
                                            tabindex="-1"
                                            value=""
                                        >
                                    </div>
                                    <div class="control mc-signup-form-submit">
                                        <button
                                            type="submit"
                                            value="Submit"
                                            name="subscribe"
                                            id="mc-embedded-subscribe"
                                            class="button mc-embedded-subscribe"
                                        >
                                            <span>Submit</span>
                                            <span class="icon is-small">
                                                <b-icon icon="arrow-right" size=""></b-icon>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!--End mc_embed_signup-->
                </div>
                <div class="column is-hidden-tablet">
                    <a class="is-pulled-left social-link" href="https://www.instagram.com/goodroutesuk/" target="_blank">
                        <b-icon icon="instagram" size="is-medium"></b-icon>
                    </a>
                </div>
                <div class="column footer-links">
                    <div class="columns is-mobile">
                        <div class="column">
                            <router-link to="/">Home</router-link>
                            <router-link to="/routes">Good Routes</router-link>
                            <router-link to="/about">About</router-link>
                            <router-link to="/account" class="account-link" v-if="is_logged_in">
                                Account
                            </router-link>
                            <a href="#" @click="showLogin()" v-else>
                                Sign Up/Login
                            </a>
                        </div>
                        <div class="column">
                            <a href="https://natalie3489.wixsite.com/good-routes-blog/privacy-policy" target="_blank">
                                Privacy Policy
                            </a>
                            <a href="https://natalie3489.wixsite.com/good-routes-blog/faqs" target="_blank">
                                FAQs
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container content has-text-centered">
            <p class="disclaimer">
                All of our routes have been hand-researched, so all prices and timetables should be
                viewed as indicative and may vary.
            </p>
            <a class="is-pulled-left social-link is-hidden-mobile" href="https://www.instagram.com/goodroutesuk/" target="_blank">
                <b-icon icon="instagram" size="is-medium"></b-icon>
            </a>
            <p>
                <img class="footer-logo" alt="Good Routes logo" src="../assets/logo.png">
                <br />
                &copy; Copyright Good Routes {{year}}
            </p>
        </div>
    </footer>
</template>

<script>
import {
    mapState,
    mapMutations,
} from 'vuex';

export default {
    computed: {
        ...mapState({
            is_logged_in: (state) => state.user.is_logged_in,
        }),
        year() {
            return (new Date()).getFullYear();
        },
    },
    methods: {
        ...mapMutations('user', [
            'showLogin',
        ]),
    },
};
</script>

<style scoped lang="scss">
    .footer {
        background-color: $black;
        color: $white;

        .title {
            color: $green;
        }

        .footer-logo {
            width: 150px;
        }

        .footer-links {
            .columns {
                width: 80%;
                float: right;
            }
            a {
                color: $white;
                display: block;
            }
        }

        .disclaimer {
            font-size: 14px;
        }

        .social-link {
            color: $white;
            &:hover {
                color: $white;
            }
        }

        .mc-signup-form {
            .mc-signup-form-fields {
                display: flex;
                .mc-signup-form-email {
                    flex: 1;
                    input {
                        background: none;
                        border: 0;
                        border-bottom: thin solid $white;
                        color: $white;
                        padding: 0;
                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            color: #999;
                        }
                        &::-moz-placeholder { /* Firefox 19+ */
                            color: #999;
                        }
                        &:-ms-input-placeholder { /* IE 10+ */
                            color: #999;
                        }
                        &:-moz-placeholder { /* Firefox 18- */
                            color: #999;
                        }
                    }
                }
                .mc-signup-form-submit {
                    button {
                        background: none;
                        border: 0;
                        color: $white;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $tablet) {
        .footer {
            .title {
                text-align: center;
            }

            .mc-signup-form .mc-signup-form-fields {
                flex-direction: column;

                .mc-signup-form-submit {
                    button {
                        background: $primary;
                        color: $white;
                        border-radius: 4px;
                    }

                    .icon {
                        display: none;
                    }
                }
            }

            .footer-links {
                .columns {
                    width: 100%;
                    float: none;
                }
            }
        }
    }
</style>
