<template>
    <div class="marked content" v-html="content"></div>
</template>

<script>
import marked from 'marked';

export default {
    props: {
        input: String,
    },
    computed: {
        content() {
            if (typeof this.input === 'undefined' || !this.input) {
                return '';
            }
            marked.setOptions({
                renderer: new marked.Renderer(),
                gfm: true,
                breaks: true,
                smartLists: true,
                smartypants: false,
            });
            return marked(this.input);
        },
    },
};
</script>

<style lang="scss">
    .welcome-text {
        .marked.content {
            h4 {
                font-size: 20px;
            }
        }
    }

    .route-description {
        p {
            font-size: 14px;
        }
    }
</style>
