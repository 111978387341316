import Vue from 'vue';
import Vuex from 'vuex';

import tag from './modules/tag';
import user from './modules/user';
import content from './modules/content';
import status from './modules/status';
import route from './modules/route';
import location from './modules/location';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        tag,
        user,
        content,
        status,
        route,
        location,
    },
});
