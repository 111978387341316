<template>
    <div class="login-wrapper modal-content p-5">
        <div class="login-form">
            <div class="login-title has-text-centered">
                <h2 class="title is-2 script-font">
                    Join our crew to view and save your Good Routes
                </h2>
                <strong>*no spam, we promise</strong>
            </div>
            <form @submit="validateLogin" class="mt-4">
                <b-field>
                    <b-checkbox
                        class="newsletter-signup"
                        v-model="newsletter"
                        true-value="1"
                        false-value="0"
                    >
                        Sign me up scottie! I'd love to get email alerts for more awesome updates
                        on new routes and travel ideas.
                    </b-checkbox>
                </b-field>
                <b-field
                    :type="email_type"
                    :message="email_msg"
                >
                    <b-input
                        :disabled="disabled"
                        type="email"
                        placeholder="Email address"
                        autocomplete="email"
                        v-model="email">
                    </b-input>
                </b-field>
                <b-field
                    :type="password_type"
                    :message="password_msg"
                    v-if="!forgot_password"
                >
                    <b-input type="password"
                        :disabled="disabled"
                        v-model="password"
                        password-reveal
                        placeholder="Password"
                        autocomplete="current-password"
                    >
                    </b-input>
                </b-field>
                <template v-if="forgot_password">
                    <button
                        class="button btn-hollow"
                        @click="forgot_password = false"
                    >
                        Cancel
                    </button>
                    <button
                        class="button has-text-white is-primary is-pulled-right"
                        @click="sendResetLink(email)"
                    >
                        Reset
                    </button>
                </template>
                <template v-else>
                    <button
                        class="button btn-hollow"
                        :disabled="disabled"
                    >
                        Login
                    </button>
                    <button
                        class="button has-text-white is-primary is-pulled-right btn-signup"
                        @click="validateRegistration"
                        :disabled="disabled"
                    >
                        Sign up
                    </button>
                </template>
            </form>
            <p class="mt-4 mb-4" v-if="!forgot_password">
                <a
                    href="#"
                    class="forgot-password"
                    @click="forgot_password = true"
                >
                    Forgot password?
                </a>
            </p>
        </div>
        <div class="signup-logo-wrapper">
            <img
                class="signup-logo"
                alt="Good Routes Sign Up Man"
                src="../assets/gr_signup_man.png"
            >
        </div>
    </div>
</template>

<script>
import {
    mapState,
    // mapGetters,
    mapMutations,
    mapActions,
} from 'vuex';

import router from '../router';

export default {
    name: 'Login',
    data() {
        return {
            newsletter: '1',
            email: '',
            email_msg: '',
            email_type: '',
            password: '',
            password_msg: '',
            password_type: '',
            disabled: false,
            forgot_password: false,
        };
    },
    computed: {
        ...mapState('user', [
            'password_pattern',
            'password_pattern_message',
            'is_logged_in',
        ]),
    },
    methods: {
        ...mapMutations('status', [
            'setStatus',
        ]),
        ...mapMutations('user', [
            'hideLogin',
        ]),
        ...mapActions('user', [
            'login',
            'register',
            'getUserData',
            'sendResetLink',
        ]),
        validateForm() {
            let is_valid = true;
            // check email
            if (!this.email.match(/.+@.+\..+/)) {
                this.email_msg = 'Invalid email address';
                this.email_type = 'is-danger';
                is_valid = false;
            } else {
                this.email_msg = '';
                this.email_type = '';
            }
            // check password
            if (this.password.length) {
                // build regex
                const re = new RegExp(this.password_pattern, 'g');
                if (this.password.match(re)) {
                    // all ok
                    this.password_msg = '';
                    this.password_type = '';
                } else {
                    this.password_msg = this.password_pattern_message;
                    this.password_type = 'is-danger';
                    is_valid = false;
                }
            } else {
                this.password_msg = 'Password cannot be blank';
                this.password_type = 'is-danger';
                is_valid = false;
            }
            return is_valid;
        },
        async validateLogin(e) {
            this.$mixpanel.track('Login Attempt');
            e.preventDefault();

            const is_valid = this.validateForm();

            if (is_valid) {
                this.disabled = true;
                const data = {
                    email: this.email,
                    password: this.password,
                };
                try {
                    await this.login(data);
                    // once logged in, get user data
                    if (this.is_logged_in) {
                        this.hideLogin();
                        await this.getUserData();
                        // see if we have a redirect route to use
                        if (typeof this.$route.query.redirect !== 'undefined') {
                            router.push(this.$route.query.redirect);
                        } else {
                            // if we have a validation string, pass during login
                            const query = {};
                            if (typeof this.$route.query.validation !== 'undefined') {
                                query.validation = this.$route.query.validation;
                            }
                            // by default go to the account page on login
                            router.push({
                                path: '/account',
                                query,
                            });
                        }
                    } else {
                        this.disabled = false;
                    }
                } catch (err) {
                    this.setStatus({
                        msg: err.toString(),
                        type: 'danger',
                    });
                    this.disabled = false;
                }
            }
        },
        async validateRegistration() {
            const is_valid = this.validateForm();
            this.$mixpanel.track('Registration Attempt');

            if (is_valid) {
                this.disabled = true;
                const data = {
                    email: this.email,
                    password: this.password,
                    newsletter: this.newsletter,
                };
                try {
                    const is_registered = await this.register(data);
                    this.disabled = false;
                    if (is_registered) {
                        this.hideLogin();
                        // see if we have a redirect route to use
                        if (typeof this.$route.query.redirect !== 'undefined') {
                            router.push(this.$route.query.redirect);
                        } else {
                            // by default go to the account page on login
                            router.push('/account');
                        }
                    }
                } catch (err) {
                    this.setStatus({
                        msg: err.toString(),
                        type: 'danger',
                    });
                }
            }
        },
    },
};
</script>

<style scope lang="scss">
    .modal {
        .login-wrapper.modal-content {
            max-width: 90%;
            width: 700px;
            background-color: $skyBlue;
            display: flex;
            align-items: center;

            .login-title {
                .title.is-2 {
                    margin-bottom: 0;
                }
            }
            .login-form {
                flex: 2;

                .btn-hollow {
                    background: none;
                    border: 2px solid $black;
                }

                .btn-signup {
                    color: $white;
                }

                .forgot-password {
                    text-decoration: underline;
                }

                input {
                    background: none;
                    box-shadow: none;
                    border: 0;
                    border-bottom: thin solid $black;
                    border-radius: 0;
                    padding: 0;
                }

                .newsletter-signup {
                    align-items: start;
                }
            }
            .signup-logo-wrapper {
                flex: 1;
                text-align: center;
            }
        }
    }

    @media screen and (max-width: $tablet) {
        .modal {
            .login-wrapper.modal-content {
                margin: 0 auto;
                flex-direction: column;
            }
        }
    }
    @media screen and (min-width: $tablet) {
        .modal {
            .login-wrapper.modal-content {
                .login-form {
                    margin-right: 1.5em;
                }
            }
        }
    }
</style>
