<template>
    <section class="section home-section" :class="['is-'+themeclass]">
        <div class="container has-text-centered">
            <div class="columns welcome-text">
                <div class="column">
                    <h2 class="title script-font has-text-centered">{{ msg }}</h2>
                    <Marked :input="text" />
                </div>
                <div class="column">
                    <img
                        alt="Good Routes swinging man mascot"
                        src="../assets/gr_swinging_man.gif"
                    />
                </div>
            </div>
            <PlusPoints />
            <a href="#" class="button is-primary has-text-white mt-4" @click="scrollToTop">
                Get started
            </a>
        </div>
    </section>
</template>

<script>
import Marked from '@/components/Marked.vue';
import PlusPoints from '@/components/PlusPoints.vue';

export default {
    props: {
        msg: String,
        text: String,
        themeclass: String,
    },
    components: {
        Marked,
        PlusPoints,
    },
    methods: {
        scrollToTop() {
            const search = document.getElementById('home-search-form');
            this.$smoothScroll({
                scrollTo: search,
                hash: '#search',
            });
        },
    },
};
</script>

<style scoped lang="scss">
.welcome-text {
    .title {
        font-size: 62px;
        line-height: 79px;
    }
}

@media (max-width: $tablet) {
    .welcome-text {
        display: flex;
        flex-direction: column-reverse;

       .title {
            font-size: 41px;
            line-height: 37px;
        }
    }

}
</style>
